@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* walletConnect modal */
  wcm-modal {
    @apply z-[150] relative;
  }

  body {
    @apply bg-dark-default;
  }
}

@layer components {
  div.react-tooltip[role="tooltip"] {
    @apply max-w-[80vw] md:max-w-[260px];
    @apply bg-dark-light;
    @apply p-3;
    @apply text-12 font-light text-white text-center;
    @apply shadow-[0px_2px_8px_rgba(0,0,0,0.6)];
    @apply opacity-100;
    @apply rounded-[12px];
    @apply z-[200];
  }

  [data-tooltip-id] {
    @apply hover:cursor-pointer;
  }
}
